import LazyLoad from 'vanilla-lazyload';
import { toggleInfoPanel } from './components/toggleInfoPanel.js';
import { initProjectSlider, initProjectSliderCursor } from './components/initProjectSlider.js';

if (document.querySelector('.lazy')) {
	var ll = new LazyLoad();
}

if (document.querySelector('[data-panel-info]')) {
	toggleInfoPanel();
}

if (document.querySelector('.project-wrapper .slider')) {
	initProjectSlider();
	initProjectSliderCursor();
}
