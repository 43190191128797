/* panel about function */

function toggleInfoPanel() {
	const buttons = document.querySelectorAll('[data-panel-info]');
	const panel = document.querySelector('.panel-info');

	function togglePanel(event) {
		event.preventDefault(); // avoid jump scroll to top

		if (panel.classList.contains('is-open')) {
			panel.classList.remove('is-open');
		} else {
			panel.classList.add('is-open');
		}
	}

	buttons.forEach((button) => {
		button.addEventListener('click', togglePanel);
	});
}

export { toggleInfoPanel };
